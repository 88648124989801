



import React from "react";
import { graphql } from "gatsby";
import Banner from "./Banner";
import BlogContent from "./BlogContent";
import ExploreCard from "./ExploreCard";
import SocialShare from "./SocialShare";


const BlogModules = (props) => {
  const modules = props.modules.blogContent;
  const components = {
    Banner,
    BlogContent,
    SocialShare,
    ExploreCard
  };
  return (
    <>
      {modules
        ? modules.map((module, i) => {
            const moduleName = module.__typename.replace(
              "WpPost_Acfblogcontent_BlogContent_",
              ""
            );
            return (
              components[moduleName] &&
              React.createElement(components[moduleName], {
                key: i,
                module,
                i,
                postData: props.postData,
              })
            );
          })
        : null}
    </>
  );
};

export default BlogModules;

export const BlogModulesFragment = graphql`
  fragment BlogModulesFragment on WpPost {
    AcfBlogContent {
      blogContent {
        __typename
        ...BannerBlogFragment
        ...BlogContentBlogFragment
        ...SocialShareBlogFragment
      }
    }
  }
`;