import React, { useState ,useEffect} from "react";
import { BlogListingData } from "./BlogListingData";
import parse from "html-react-parser";
import { Link, graphql } from "gatsby";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { Navigation, Pagination, EffectFade } from "swiper";

const ExploreCard = ({ module, i }) => {
  const postData = BlogListingData();
  const post = postData.posts.edges;
  const [isLastImage, setisLastImage] = useState(true);

  useEffect(() => {
    document.body.classList.remove("menu-open");
    gsap.registerPlugin(ScrollTrigger);
    gsap.utils.toArray(".fade-ani").forEach(function (box) {
      ScrollTrigger.create({
        trigger: box,
        toggleActions: "play none none none",
        once: true,
        onEnter: function () {
          gsap.fromTo(
            box,
            { autoAlpha: 0, y: 90 },
            { duration: 1, autoAlpha: 1, y: 0 }
          );
        },
      });
    });
  }, []);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal(data) {
    document.body.classList.add("modal-open");
    setIsOpen(true);
  }

  function closeModal() {
    document.body.classList.remove("modal-open");
    setIsOpen(false);
  }
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0",
      border: "0",
      borderRadius: "0",
    },
    overlay: {
      zIndex: "99",
    },
  };
  const handleNextClick = () => {
    const swiper = document.querySelector(".contentSlider").swiper;
    swiper.slideNext();
  };
  const handlePrevClick = () => {
    const swiper = document.querySelector(".contentSlider").swiper;
    if (swiper.activeIndex === 1) {
      setisLastImage(true);
    }
    swiper.slidePrev();
  };

  return (
    <>
      <section className="content-with-slider half-content-slider my-30 lg:my-60 lg:py-120 py-60 blog-content-slider bg-lightGreen bg-opacity-4">
        <div className="container-fluid !pr-0">
          <div className="lg:flex items-center justify-between ">
            <div className="lg:w-4/12">
              <div className="content mb-30 lg:pr-0 pr-30 lg:mb-0 fade-ani max-w-auto">
                <div className="title-green mb-30">
                  <h2>Discover our other blogs like this one</h2>
                </div>
                <p>
                  Step into our world by reading our safari blog from the Kruger
                  National Park.
                </p>
                <div className="swiper-arrow">
                  <a
                    className={`button-prev cursor-pointer ${
                      isLastImage ? "opacity-50" : ""
                    } `}
                    onClick={handlePrevClick}
                  >
                    <img src="../../images/swiper-next.svg" alt="" />
                  </a>
                  <a
                    className="button-next cursor-pointer"
                    onClick={handleNextClick}
                  >
                    <img src="../../images/swiper-next.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="lg:w-7/12">
              <Swiper
                //slidesPerView={1.2}
                spaceBetween={30}
                speed={1000}
                onSlideChange={(e) => {
                  const swiper =
                    document.querySelector(".half-content-slider .contentSlider").swiper;
                  swiper.activeIndex === 0
                    ? setisLastImage(true)
                    : setisLastImage(false);
                }}
                loop={false}
                autoplay={{
                  delay: 5000,
                }}
                breakpoints={{
                  0: {
                    spaceBetween: 15,
                    slidesPerView: 1,
                  },
                  640: {
                    spaceBetween: 15,
                    slidesPerView: 1.2,
                  },
                  768: {
                    slidesPerView: 1.2,
                    spaceBetween: 20,
                  },
                  1024: {
                    spaceBetween: 30,
                    slidesPerView: 1.2,
                  },
                  2300: {   
                    slidesPerView: 1.2,
                  },
                }}
                className="contentSlider"
              >
                {post.map((item, i) => {
                  return (
                    <SwiperSlide>
                      <div className="image-wrapper">
                        <GatsbyImage
                          image={getImage(item.node.featuredImage.node)}
                          alt="blog"
                        ></GatsbyImage>
                      </div>

                      <div className="content-wrapper pt-25 pb-10">
                        <div className="title-black">
                          <h4 className="mb-15">{item.node.title}</h4>
                          {parse(item.node.excerpt)}
                        </div>
                        <Link to={item.node.uri} className="mt-30 green-btn">
                          Explore
                        </Link>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExploreCard;
export const ExploreCardFragment = graphql`
  fragment ExploreCardFragment on WpPage_Acfpagecontent_PageContent_ExploreCard {
    # content
    id
    extraClass
  }
`;
