import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";

const SocialShare = ({ module, i, postData }) => {
  return (
    <>
      <div className="container-fluid">
        <div className="global-content">
          <div className="lg:w-11/12 w-full mx-auto">
            <div className="lg:px-60 px-0">
                <div className="share-story flex items-center gap-30">
                  <h3>Share this story</h3>
                  <ul className="flex flex-wrap gap-20  items-center list-none">
                    <li>
                      <Link to="/">
                        <img src="../../images/instagram.svg" alt="instagram" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <img src="../../images/facebook.svg" alt="facebook" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default SocialShare;

export const SocialShareBlogFragment = graphql`
  fragment SocialShareBlogFragment on WpPost_Acfblogcontent_BlogContent_SocialShare {
    # content
    id
    fieldGroupName
    extraClass
  }
`;
