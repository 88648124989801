import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

const Banner = ({ module, i, postData }) => {
  return (
    <>
      <section className="hero-section inner-page-banner ">
        <div className="h-100 w-100 min-h-700 relative">
          <BgImage
            className="banner-bg-img h-full bg-cover home-banner-full"
            Tag="section"
            image={getImage(module.backgroundImage)}
            preserveStackingContext
          ></BgImage>
          <div className="absolute top-0 left-0 right-0 bottom-0 flex align-middle items-center justify-center p-15">
            <div className="text-center content-text">
              <div className="fade-ani">
                <h1 className="text-white mb-15 mx-auto"> {module.title}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;

export const BannerBlogFragment = graphql`
  fragment BannerBlogFragment on WpPost_Acfblogcontent_BlogContent_Banner {
    # content
    id
    title
    fieldGroupName
    extraClass
    backgroundImage {
      gatsbyImage(width: 1000, formats: WEBP, placeholder: BLURRED)
      altText
      uri
      title
    }
  }
`;
