import { graphql, useStaticQuery } from "gatsby";

export const BlogListingData = () => {
  const data = useStaticQuery(graphql/* GraphQL */ `
    query WpBlogsPost {
      posts: allWpPost {
        edges {
          node {
            categories {
              nodes {
                posts {
                  nodes {
                    uri
                    title
                    featuredImage {
                      node {
                        altText
                        gatsbyImage(
                          width: 1000
                          formats: WEBP
                          placeholder: BLURRED
                        )
                        uri
                      }
                    }
                  }
                }
                name
                count
              }
            }
            title
            uri
            excerpt
            featuredImage {
              node {
                altText
                gatsbyImage(
                  width: 1000
                  formats: WEBP
                  placeholder: BLURRED
                )
                title
                uri
              }
            }
          }
        }
      }
    }
  `);
  return data;
};