import React, { useEffect } from "react";
import Layout from "../components/layout";
import BlogModules from "../components/ModuleBlog/BlogModules";
import { graphql } from "gatsby";
import Seo from "../components/Seo/Seo";

const PageTemplate = (props) => {
  
  useEffect(() => {
    document.body.classList.remove("mega-menu-open");
    document.body.classList.remove("booking-open");
    document.body.classList.remove("form-enquiry-open");
    document.body.classList.remove("menu-open");
  });

  const post = props.data.post;
  return (
    <Layout>
      <BlogModules modules={post.AcfBlogContent} postData={post} />
    </Layout>
  );
};

export default PageTemplate;
export const pageQuery = graphql/* GraphQL */ `
  query BlogById($id: String!) {
    post: wpPost(id: { eq: $id }) {
      id
      title
      featuredImage {
        node {
          altText
          gatsbyImage(width: 1000, formats: WEBP, layout: FULL_WIDTH)
        }
      }
      seo {
        canonical
        fullHead
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
      }
      ...BlogModulesFragment
    }
  }
`;
export const Head = ({ data }) => {
  const post = data.post
  return <Seo seo={post.seo} />
}