import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";

const BlogContent = ({ module, i, postData }) => {
  return (
    <>
      <section className="blog-single short-content lg:py-60 py-30 mt-30 lg:mt-60">
        <div className="container-fluid">
          <div className="w-full m-auto fade-ani">
            <div className="global-content">
              <div className="title-green mb-30 lg:mb-60 text-center">
                <h2>Blog by Jock Safari Lodge</h2>
              </div>
              <div className="lg:w-11/12 w-full mx-auto">
                {module.blogDetails.map((item, i) => {
                 return <>
                    {item.description != null && (
                      <div className="global-list lg:px-60 px-0">
                        {parse(item.description)}
                      </div>
                    )}
                    {item.image != null && (
                      <div className="img-content">
                        <GatsbyImage
                          image={getImage(item.image)}
                          alt="blog"
                        ></GatsbyImage>
                      </div>
                    )}
                  </>;
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogContent;

export const BlogContentBlogFragment = graphql`
  fragment BlogContentBlogFragment on WpPost_Acfblogcontent_BlogContent_BlogContent {
    # content
    id
    heading
    fieldGroupName
    extraClass
    blogDetails {
      fieldGroupName
      description
      image {
        gatsbyImage(width: 1000, formats: WEBP, placeholder: BLURRED)
        altText
        uri
        title
      }
    }
  }
`;
